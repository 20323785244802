import { Fragment } from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import styles from './Home.module.scss';
import PayCard from '~/components/PayCard';
import Sliders from '~/components/Sliders';
import FreeFire from '~/components/FreeFire';
import LoadHistory from '~/components/LoadHistory';
import Notification from '~/components/Notification';

const cx = classNames.bind(styles);

function Home() {
    const { currentUser } = useSelector((state) => state.auth);
    const { userLogin, history_charging } = useSelector((state) => state.setting);

    return (
        <div className={cx('wrapper')}>
            <div className={cx('container')}>
                <Sliders />

                <div className={cx('border')}>
                    <div className={!userLogin || currentUser ? cx('line') : cx('line', 'active')}></div>
                    {!userLogin || currentUser ? (
                        <Fragment>
                            <div>
                                <img src="https://cdn-gop.garenanow.com/gop/app/0000/100/067/icon.png" alt="Free Fire" />
                            </div>
                            <h1 className={cx('game')}>Chọn phương thức thanh toán</h1>
                            <div
                                className={cx('reload')}
                                onClick={() => {
                                    window.location.reload();
                                    localStorage.removeItem('_re');
                                    localStorage.removeItem('timeout');
                                }}
                            >
                                <span className={cx('icon-reload')}></span>
                                <span className={cx('text-reload')}>Làm mới</span>
                            </div>
                        </Fragment>
                    ) : (
                        <div className={cx('login')}>Đăng nhập để nạp</div>
                    )}
                    <div className={!userLogin || currentUser ? cx('line') : cx('line', 'active')}></div>
                </div>
                {!userLogin || currentUser ? (
                    <Notification>Ưu đãi chỉ áp dụng cho 1 số phương thức thanh toán vui lòng kiểm tra kĩ trước khi nạp</Notification>
                ) : (
                    <Fragment></Fragment>
                )}

                {!userLogin || currentUser ? <PayCard /> : <FreeFire />}

                {history_charging && <LoadHistory />}
            </div>
        </div>
    );
}

export default Home;
